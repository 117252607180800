<template>
  <div class="user">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__agencyAccountManagement") }}</h2>
      <div class="d-flex align-items-center justify-content-between mb-3 flex-wrap">
        <div class="d-flex align-items-center me-2 mb-2">
          <div class="device-report-select">
            <select v-model="searchAgency" class="form-select rounded-pill me-2">
              <option :value="null">{{ $t("__all") + " ( " + $t('__agencyName') + ' / ' + $t('__codeName') + " )"}}</option>
              <option v-for="item in allAgencys" :value="item.id" :key="'agent'+ item.id">
                {{ item.name + ' / ' + item.codename }}
              </option>
            </select>
          </div>
        </div>
        <form @submit.prevent="searchUser"  class="me-auto me-2 mb-2">
          <div class="input-group rounded-pill">
            <input
              v-model="search"
              type="text"
              class="form-control rounded-pill"
              :placeholder="$t('__searchUserName')"
              aria-label="search"
              aria-describedby="search"
            />
            <button
              type="submit"
              class="btn btn-link px-2 text-dark"
              id="search"
              @click="searchUser"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              v-if="filter"
              type="button"
              class="btn btn-primary px-3 ms-1 rounded-pill"
              id="clearSearch"
              @click="clearSearch"
            >
              {{ $t("__clearSearch") }}
            </button>
          </div>
        </form>
        <button
          @click="showModal()"
          class="mb-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm"
        >
          <i class="bi bi-plus"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-body">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(agency)="data">
              {{ data.item.agency.name + " / " + data.item.agency.codename }}
            </template>
            <template v-slot:cell(status)="data">
              <div class="status-switch" v-if="isSystemManage ? data.item.manager_id === userInfo.id : true">
                <input
                  type="checkbox"
                  :id="'switch-' + data.index"
                  v-model="data.item.status"
                  @click="changeStatusAlert(data.item.id)"
                />
                <label :for="'switch-' + data.index"></label>
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex" v-if="isSystemManage ? data.item.manager_id === userInfo.id : true">
                <button
                  @click="showModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1 fs-5"></i>
                </button>
                <button
                  @click="resetPasswordAlert(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__resetPassword')"
                >
                  <i class="bi bi-arrow-counterclockwise fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
            entNub: currentPage == 1
              ? totalRows > perPage
                ? perPage
                : totalRows
              : perPage * (currentPage - 1) + perPage > totalRows
              ? totalRows
              : perPage * (currentPage - 1) + perPage ,
            sum: totalRows
            })
          }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="edit" ref="editModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ isEdit ? $t("__editAgencyUser") : $t("__addAgencyUser") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="username" class="form-label fw-medium required">{{
                  $t("__userName")
                }}</label>
                <input
                  v-model="modalData.username"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg.username
                  }"
                  id="username"
                  required
                  :readonly="isEdit"
                />
                <div class="invalid-feedback" v-if="validate && validate.msg.username">
                  <div v-for="(msg, index) in validate.msg.username" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="name" class="form-label fw-medium required">{{
                  $t("__name")
                }}</label>
                <input
                  v-model="modalData.name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg.name
                  }"
                  id="name"
                  required
                />
                <div class="invalid-feedback" v-if="validate && validate.msg.name">
                  <div v-for="(msg, index) in validate.msg.name" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="agency_id" class="form-label fw-medium required">{{
                  $t("__agencyCodename") + " / " + $t("__agencyName")
                }}</label>
                <select
                  v-model="modalData.agency_id"
                  class="form-select"
                  :class="{
                    'is-invalid': validate && validate.msg.agency_id
                  }"
                  id="agency_id"
                  :disabled="isEdit"
                  required
                >
                  <option :value="null" selected>
                    {{ $t("__openThisSelectMenu") }}
                  </option>
                  <option
                    v-for="agency in agencys"
                    :key="'agency' + agency.id"
                    :value="agency.id"
                  >
                    {{ agency.codename + " / " + agency.name }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="validate && validate.msg.agency_id">
                  <div v-for="(msg, index) in validate.msg.agency_id" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="resetPasswordModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="resetPasswordModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__resetPassword") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__resetPasswordNote", {Agency: modalData.name}) }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="resetPassword"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="changeStatusModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="changeStatusModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__changeStatus") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__changeStatusNote", {Agency: modalData.name}) }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="changeStatus"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { user } from '@/http/api/user.js'
import { agency } from '@/http/api/agency.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'User',
  data () {
    return {
      fields: [
        {
          key: 'username',
          label: i18n.t('__userName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'name',
          label: i18n.t('__name'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'agency',
          label: i18n.t('__agencyName') + ' / ' + i18n.t('__codeName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'status',
          label: i18n.t('__status'),
          class: 'align-middle text-center',
          sortable: false
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle',
          sortable: false
        }
      ],
      agencys: null,
      allAgencys: null,
      users: null,
      filter: null,
      filterOn: ['username'],
      search: null,
      searchAgency: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null,
      bootstrap_modal: null,
      isEdit: false,
      editModalId: null,
      modalData: {
        status: null,
        name: null,
        username: null,
        agency_id: null
      },
      validate: null
    }
  },
  computed: {
    ...mapState(['token', 'userInfo', 'isSystemManage']),
    items () {
      const vm = this
      let userFilter = vm.users
      if (vm.searchAgency) {
        userFilter = userFilter.filter(item => vm.searchAgency === item.agency_id)
      }
      return userFilter
    }
  },
  watch: {
    items (data) {
      const vm = this
      if (data) {
        vm.initBootstrapTooltip()
        vm.totalRows = data.length
        vm.currentPage = 1
      }
    },
    modalData: {
      handler: function () {
        this.validate = null
      },
      deep: true
    },
    userInfo () {
      this.getAgency()
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getUser () {
      const vm = this
      vm.Loading()
      user
        .get('', vm.token)
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.users = res.data.data
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
          vm.Loaded()
        })
    },
    getAgency () {
      if (!this.userInfo) return
      const vm = this
      var config = Object.assign({}, this.token)
      config.params = {
        managerId: this.userInfo.id
      }
      agency.get('', config).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.agencys = res.data.data
        }
      })
    },
    getAllAgency () {
      const vm = this
      agency.get('', this.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.allAgencys = res.data.data
        }
      })
    },
    searchUser () {
      this.filter = this.search
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearSearch () {
      this.filter = null
      this.search = null
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    showModal (id) {
      const vm = this
      vm.editModalId = id
      if (id) {
        vm.isEdit = true
        vm.items.forEach((item) => {
          if (item.id === id) {
            vm.modalData.name = item.name
            vm.modalData.username = item.username
            vm.modalData.agency_id = item.agency_id
          }
        })
      } else {
        vm.isEdit = false
        vm.modalData.name = null
        vm.modalData.username = null
        vm.modalData.agency_id = null
      }
      vm.bootstrap_modal = new Modal(vm.$refs.editModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    submitForm () {
      const vm = this
      vm.Loading()
      if (vm.editModalId) {
        user
          .update(
            vm.editModalId,
            {
              name: vm.modalData.name
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getUser()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.validate = res.data.validate
            }
            vm.Loaded()
            vm.Alerted()
          })
      } else {
        user
          .create(
            {
              name: vm.modalData.name,
              username: vm.modalData.username,
              agency_id: vm.modalData.agency_id
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getUser()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__createSuccess')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.validate = res.data.validate
            }
            vm.Loaded()
            vm.Alerted()
          })
      }
    },
    resetPasswordAlert (id) {
      const vm = this
      vm.editModalId = id
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.name = item.name
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.resetPasswordModal)
      vm.bootstrap_modal.show()
    },
    resetPassword () {
      const vm = this
      vm.Loading()
      user.resetPassword(vm.editModalId, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__resetPassword') + i18n.t('__success')
          })
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
        }
        vm.Loaded()
        vm.Alerted()
        vm.bootstrap_modal.hide()
      })
    },
    changeStatusAlert (id) {
      const vm = this
      vm.editModalId = id
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.name = item.name
          vm.modalData.status = !item.status
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.changeStatusModal)
      vm.bootstrap_modal.show()
      vm.$refs.changeStatusModal.addEventListener('hidden.bs.modal', () => {
        vm.getUser()
      })
    },
    changeStatus () {
      const vm = this
      vm.Loading()
      user.changeStatus(vm.editModalId, {
        status: vm.modalData.status
      }, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__changeStatus') + i18n.t('__success')
          })
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
        }
        vm.Loaded()
        vm.Alerted()
        vm.bootstrap_modal.hide()
      })
    }
  },
  created () {
    this.getUser()
    this.getAgency()
    this.getAllAgency()
  },
  mounted () {}
}
</script>

<style  lang="scss">
</style>
